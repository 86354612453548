import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EyeIcon from '../../assets/images/icons/eye.svg';

import Rails from '@rails/ujs';

export default class FindMoreButton extends Component {
  render() {
    const { filtersString, loading, origin } = this.props;
    const mixpanelData = JSON.stringify({ 'Page Navigated From': origin });

    return (
      <div>
        <form action={'/key_decision_makers'} method='post' ref={(form) => { this.form = form; }} className='py-2'>
          <button
            className='flex justify-between gap-x-1.5 py-1.5 px-2 items-center bg-grey-700 hover:bg-grey-800 font-medium text-grey-50 rounded w-full text-start'
            // The traversal requires data from the KDM module and the
            // functions tree. Both need to be present, before we enable the
            // the button.
            disabled={loading}
            type="submit"
          >
            <span>
              Find more key decision-makers
            </span>
            <EyeIcon className='fill-grey-50'></EyeIcon>
          </button>
          <input type="hidden" name="filters" value={filtersString}/>
          <input type="hidden" name="mixpanel_event" value={'Navigate to KDM page from module'}/>
          <input type="hidden" name="mixpanel_data" value={mixpanelData}/>
          <input type="hidden" name={ Rails.csrfParam() } value={ Rails.csrfToken() || '' }/>
        </form>
      </div>
    );
  }
}

FindMoreButton.propTypes = {
  origin: PropTypes.string.isRequired,
  filtersString: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};
